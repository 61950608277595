import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterImage'
})
export class FilterImagePipe implements PipeTransform {

  transform(value: any[]): any[] {
    const filteredImages = [];
    value = value?.map(item => {
      
      if(!item.isVideo){
        filteredImages.push(item);
      }
    })
    return filteredImages;
  }

}
