import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pares'
})
export class ParesPipe implements PipeTransform {

   transform( arr:any[], arg:string ):any[] {
    let pair = arr;
    if(arg === 'pair'){
      pair = arr?.reduce((result, value, index, array) => {
        if(index % 2 === 0){
          result.push(array.slice(index, index + 2));
        }
        return result;
      }, []);
      return pair;
    }else{
      return pair;
    }

  }

}
