import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnInit {
  @Input() langs: any[] = [];

  constructor(
    private _langSv: LangService,
    private _popoverController: PopoverController
  ) {}

  ngOnInit() {}

  async setLanguage(lang) {
    this._langSv.setLang(lang);
    await this._popoverController.dismiss();
  }
}
