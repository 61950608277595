import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BranchService } from 'src/app/services/branch.service';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-change-branch',
  templateUrl: './change-branch.component.html',
  styleUrls: ['./change-branch.component.scss'],
})
export class ChangeBranchComponent implements OnInit {
  @Input() availableBranch: any[] = [];

  constructor(
    private popoverController: PopoverController,
    private storageSv: StorageService,
    public loginSv: LoginService,
    public paramSv: ParamServicesService,
    public branchSv: BranchService
  ) {}

  async ngOnInit() {
    if (!this.branchSv.branches.length) {
      this.branchSv.getBranchs();
    }
  }

  selectBranch(item) {
    this.close(item);
  }

  async close(item?) {
    if (item) {
      this.storageSv.setLocalStorage('branchContactId', item.id);
      this.loginSv.branchContactId = item.id;
      this.paramSv.headerOpts = [];
      this.paramSv.reload.emit();
    }
    await this.popoverController.dismiss(item);
  }
}
