import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BalanceService } from 'src/app/services/balance.service';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'Amarillas-multio',
  templateUrl: './multio.component.html',
  styleUrls: ['./multio.component.scss'],
})
export class MultioComponent implements OnInit {
  public currencies: any[] = [];

  constructor(
    private balanceSv: BalanceService,
    private popoverController: PopoverController,
    private paramSv: ParamServicesService
  ) {}

  async ngOnInit() {
    try {
      const currencies: any = await this.balanceSv.getCurrencies();
      const defaultCurrencie = await this.paramSv.defaultCurrency;
      defaultCurrencie && currencies.unshift(defaultCurrencie);
      this.currencies = currencies;
    } catch (err) {
      console.error(err);
    }
  }

  async closePopover(currency?) {
    await this.popoverController.dismiss(currency);
  }

  async setCurrency(currency) {
    localStorage.setItem('currency', JSON.stringify(currency));
    this.balanceSv.setCurrency(currency);
    this.closePopover(currency);
  }
}
