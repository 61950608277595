import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private loginSv: LoginService,
    private navCtrl: NavController
  ){}
  
  async canActivate(): Promise<any> {
    const user = await this.loginSv.getTokenUser();
    const type = window.location.href.split('?')[1];
    if(type){
      if(type === 'registerType=dist'){
        await this.loginSv.logout('dist');
        return true;
      }
    }
    if(user.includes('undefined')){
      return true;
    }else{
      this.navCtrl.navigateRoot('home');
      return false;
    }
  }
  
}
