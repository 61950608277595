import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { UiServices } from './ui-services';
import { ChangeBranchComponent } from '../components/change-branch/change-branch.component';
import { ParamServicesService } from './param-services.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private _domainId = environment.domainId;
  public branches: any[] = [];

  constructor(
    private httpSv: HttpService,
    private uiSv: UiServices,
    private paramSv: ParamServicesService,
    private loginSv: LoginService
  ) {}

  async getBranchs() {
    const params = {
      domainId: this._domainId,
    };
    const res = await this.httpSv.itemIndex(
      'branchContact/product',
      '',
      params
    );
    res.data.forEach((x) => {
      x.value = x.id;
    });
    this.branches = res.data;
    this.branches.unshift({
      id: 0,
      name: 'Todos',
    });
  }

  comprobeToAdd(): boolean {
    if (this.paramSv.dispatch.branch && !this.loginSv.branchContactId) {
      return false;
    } else {
      return true;
    }
  }

  async showAvailableBranch(defaultTxt, data) {
    const { role } = await this.uiSv.presentAlert(
      defaultTxt,
      false,
      'CONTINUAR',
      false
    );
    if (role === 'accept') {
      await this.uiSv.showPopover(
        ChangeBranchComponent,
        {
          availableBranch: data.inventory,
        },
        'branch-pop'
      );
    }
  }

  async setSocial(social, value, customMsg, gallery) {
    let url = '';
    if (social === 'tg') url = `https://t.me/${value}`;
    else if (social === 'wp')
      url = `https://wa.me/${value}?text=${
        customMsg || this.paramSv.msgGalleryWpp || ''
      } ${gallery?.name || ''}`;
    else if (social === 'email') {
      await navigator.clipboard.writeText(value);
      await this.uiSv.showToast('Copiado al portapapeles');
    }
    return url;
  }
}
