import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer',
})
export class DomSanitizerPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(data: string, type: string) {
    if (type === 'image') {
      const safeImgUrl = this.domSanitizer.bypassSecurityTrustStyle(
        `background: url(${data}); background-position: center; background-size: cover; background-repeat: no-repeat;`
      );
      return safeImgUrl;
    } else if (type === 'html') {
      const value = this.domSanitizer.bypassSecurityTrustHtml(data);
      return value;
    }
  }
}
