import { Pipe, PipeTransform } from '@angular/core';

type TProduct = {
  price: number;
  promotionalPrice: number;
  isActive: number;
  inscriptionPrice: number;
  frequency: string;
  duesQuantity: number;
  includesDues: number;
};

@Pipe({
  name: 'calcSubscriptionPrice',
})
export class CalcSubscriptionPricePipe implements PipeTransform {
  transform(value: number, product: TProduct): number {
    if (product?.includesDues) {
      const price = value + product.inscriptionPrice;
      return price;
    }
    return value;
  }
}
