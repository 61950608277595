// import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  // Inject,
  OnInit,
  PLATFORM_ID,
  // PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonMenu, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SplashComponent } from './components/splash/splash.component';
import { BalanceService } from './services/balance.service';
import { CartServiceService } from './services/cart-service.service';
import { HttpService } from './services/http.service';
import { LangService } from './services/lang.service';
import { LoginService } from './services/login.service';
import { MetadataService } from './services/metadata-service.service';
import { ParamServicesService } from './services/param-services.service';
import { PwaService } from './services/pwa.service';
import { StorageService } from './services/storage.service';
import { UiServices } from './services/ui-services';
import { VersionControllerService } from './services/version-controller.service';
import { CarruselService } from './services/carrusel.service';
import { Types } from './constants/gift-card-type';
import { PushNotificationsService } from './services/push-notifications.service';
import { isPlatformBrowser } from '@angular/common';

declare var WebFont: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('menu') ionMenu: IonMenu;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(event: any) {
    this.pwaSv.deferredPrompt = event;
  }
  @HostListener('window:appinstalled', ['$event'])
  oninstalled(event: any) {
    this.pwaSv.backbutton = true;
  }

  public optsMenu: any[] = [];
  public roleId = undefined;
  public domainId = environment.domainId;
  public staticOpts: any[] = [];

  public buttons = undefined;
  public loaded = false;
  public colors: any[];
  public homeSections: any[];
  public subscriber: Subscription;

  constructor(
    private navCtrl: NavController,
    private elementRef: ElementRef,
    private router: Router,
    private uiSv: UiServices,
    private storageSv: StorageService,
    private httpSv: HttpService,
    private cartSv: CartServiceService,
    private pwaSv: PwaService,
    private metaSv: MetadataService,
    private langSv: LangService,
    public paramSv: ParamServicesService,
    public loginSv: LoginService,
    public balanceSv: BalanceService,
    private versionSv: VersionControllerService, // , //
    private _pushSv: PushNotificationsService,
    private carruselSv: CarruselService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.versionSv.verify();
    this.paramSv.reload.subscribe(() => {
      this.staticOpts = [];
      this.ngOnInit();
    });
    this.loginSv.emitLogin.subscribe(() => {
      this.addOptions();
    });
    this.loginSv.emitLogout.subscribe(() => {
      this.comprobeAdminOption();
      this.comprobeLogin();
    });
  }

  async closeMenu() {
    try {
      await this.ionMenu.close(true);
    } catch (error) {
      console.error('err metaHome :>>', error);
    }
  }

  async metaHome() {
    try {
      const seoData: any = await this.httpSv.getParsedParam(
        'seo',
        this.domainId
      );
      this.paramSv.seo = seoData;
      this.metaSv.metaHome(seoData);
    } catch (error) {
      console.error('err metaHome :>>', error);
    }
  }

  setDefaultButtons() {
    this.staticOpts = [
      {
        id: 4,
        name: 'PROMOCIONES',
        icon: '/assets/icons/promos.svg',
        url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=&promo=1&from=&upTo=`,
      },
      {
        id: 5,
        name: 'LO_MAS_RECIENTE',
        icon: '/assets/icons/reciente.svg',
        url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=newProducts&promo=&from=&upTo=`,
      },
      {
        id: 6,
        name: 'CAMBIAR_MONEDA',
        icon: '/assets/icons/moneda.svg',
        changeCurrency: true,
      },
      {
        id: 7,
        name: 'CAMBIAR_SUCURSAL',
        iconName: 'storefront-outline',
        branch: true,
      },
      {
        id: 8,
        name: 'MI_CUENTA',
        icon: '/assets/icons/Usuario.svg',
        url: 'profile',
      },
    ];
  }

  async ngOnInit() {
    try {
      this.metaHome();
      if (!isPlatformBrowser(this.platformId)) {
        return false;
      }
      this.setDefaultButtons();
      this.cartSv.checkCart();
      this.carruselSv.checkStatus();
      await this.langSv.initLanguage();
      await this.uiSv.showModal(SplashComponent, undefined, 'splash');
      this._pushSv.initPush();
      this.metaSv.subscribeData();
      await this.loginSv.getTokenUser();
      setTimeout(async () => {
        if (this.loginSv.token) {
          const data: any = await this.httpSv.itemIndex('auth');
          await this.loginSv.saveUser(data.user);
        }
      });
      if (this.paramSv.wallet) {
        if (
          this.paramSv.wallet.value.value === '1' &&
          !this.staticOpts.includes((x) => x.name === 'WALLET')
        ) {
          this.staticOpts.unshift({
            id: 3,
            name: 'WALLET',
            icon: '/assets/icons/wallet.svg',
            url: 'wallet',
          });
        }
      }
      this.colors = this.paramSv.colors;
      this.buttons = this.paramSv.buttons;
      this.homeSections = this.paramSv.homeSections;
      this.setFontFamily();
      this.balanceSv.setBaseCurrency(this.paramSv.defaultCurrency);
      let existCurrency: any = localStorage.getItem('currency');
      if (existCurrency === 'undefined') existCurrency = undefined;
      if (!existCurrency) {
        localStorage.setItem(
          'currency',
          JSON.stringify(this.paramSv.defaultCurrency)
        );
        this.balanceSv.setCurrency(this.paramSv.defaultCurrency);
      } else if (existCurrency) {
        let compareActualCurrency = JSON.parse(existCurrency);
        if (
          compareActualCurrency?.name === this.paramSv.defaultCurrency?.name
        ) {
          localStorage.setItem(
            'currency',
            JSON.stringify(this.paramSv.defaultCurrency)
          );
          this.balanceSv.setCurrency(this.paramSv.defaultCurrency);
        } else {
          const currencies: any[] = await this.balanceSv.getCurrencies();
          const updateCurrency = currencies.find(
            (item) => item.name === compareActualCurrency.name
          );
          localStorage.setItem('currency', JSON.stringify(updateCurrency));
          this.balanceSv.setCurrency(updateCurrency);
        }
      }
      await this.setColors();
      if (this.paramSv.requiredAuth) {
        this.subscriber = this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe((event) => {
            const logined = localStorage.getItem('logined');
            if (logined === '1') {
              this.subscriber.unsubscribe();
            } else {
              this.navCtrl.navigateForward('auth');
            }
          });
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          const valueButtons = this.buttons?.value;
          for (const key of Object.keys(valueButtons)) {
            const value = valueButtons[key];
            if (event.matches) {
              if (value?.darkBtnProperties) {
                for (const style of Object.keys(value?.darkBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.darkBtnProperties[style]
                  );
                }
              }
            } else {
              if (value?.lightBtnProperties) {
                for (const style of Object.keys(value?.lightBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.lightBtnProperties[style]
                  );
                }
              }
            }
          }
          this.colors.map((color) => {
            if (event.matches) {
              let paramDarkColor = color.darkColor;
              if (paramDarkColor.endsWith(';')) {
                paramDarkColor = paramDarkColor.slice(0, -1);
              }
              this.elementRef.nativeElement.style.setProperty(
                color.name,
                color.darkColor
              );
            } else {
              let paramColor = color.color;
              if (paramColor.endsWith(';')) {
                paramColor = paramColor.slice(0, -1);
              }
              this.elementRef.nativeElement.style.setProperty(
                color.name,
                paramColor
              );
            }
          });
          this.homeSections.map((section) => {
            const fixTitle: string = section.title
              .toLocaleLowerCase()
              .replace(/ /g, '_');
            const nameVar = `--${section.type}-${fixTitle}`;
            if (event.matches) {
              this.elementRef.nativeElement.style.setProperty(
                nameVar,
                section.darkBackgound
              );
            } else {
              this.elementRef.nativeElement.style.setProperty(
                nameVar,
                section.backgound
              );
            }
          });
        });
      this.addOptions();
    } catch (err) {
      console.error('err ngOnInit :>>', err);
    }
  }

  async comprobeLogin() {
    const existLogin = !this.staticOpts.find((item) => item.url === 'auth');
    if (existLogin) {
      this.staticOpts.unshift({
        name: 'INICIAR_SESION',
        icon: '/assets/icons/home-outline.svg',
        url: 'auth',
      });
    }
  }

  async setFontFamily() {
    try {
      const fontFamily = this.paramSv?.styles?.find(
        (item) => item.text === 'Fuente'
      )?.selected;
      if (WebFont) {
        await WebFont.load({
          google: {
            families: [`${fontFamily}:300,500,700`],
          },
        });
      }
      this.elementRef.nativeElement.style.setProperty(
        '--ion-font-family',
        `${fontFamily}, sans-serif`
      );
    } catch (error) {
      console.log('error setFontFamily :>> ', error);
    }
  }

  async addOptions() {
    try {
      setTimeout(async () => {
        const data = await this.storageSv.get('userInfo');
        this.roleId = data?.user?.rolId;
        if (!this.paramSv.ableLogin?.canLogin) {
          this.staticOpts = this.staticOpts.filter(
            (item) => item.url !== 'profile'
          );
        }
        if (!this.paramSv.headerParam[1].activate) {
          this.staticOpts = this.staticOpts.filter(
            (item) => item.name !== 'CAMBIAR_MONEDA'
          );
        }
        if (!this.paramSv.headerParam[6].activate) {
          this.staticOpts = this.staticOpts.filter(
            (item) => item.name !== 'CAMBIAR_SUCURSAL'
          );
        }
        if (
          !this.paramSv.headerParam[4].activate ||
          !this.paramSv.ableLogin?.canLogin
        ) {
          this.staticOpts = this.staticOpts.filter(
            (item) => item.name !== 'ORDENES_PENDIENTES'
          );
        }

        if (this.paramSv.availableViews) {
          const views: any[] = this.paramSv.availableViews.value.value;
          const availableMeet = views.find(
            (item) => item.view === 'Citas'
          )?.visible;
          const availableGiftCard = views.find(
            (item) =>
              item.view === 'Gift Card / Cupones' || item.view === 'Gift Card'
          )?.visible;
          const availableSubscriptions = views.find(
            (item) => item.view === 'Suscripciones'
          )?.visible;
          const existSubs = !!this.staticOpts.find(
            (item) => item.name === 'SUSCRIPCIONES'
          );

          if (availableSubscriptions && !existSubs) {
            this.staticOpts.unshift({
              id: 9,
              name: 'SUSCRIPCIONES',
              iconName: 'calendar-outline',
              url: 'inscriptions',
            });
          }
          if (availableMeet) {
            if (!this.staticOpts.find((x) => x.name === 'MI_CALENDARIO')) {
              this.staticOpts.unshift({
                id: 10,
                name: 'MI_CALENDARIO',
                iconName: 'calendar-number-outline',
                url: 'operator',
              });
            }
          }

          if (availableGiftCard) {
            if (
              !this.staticOpts.find((x) => x.name === 'MIS_GIFTCARDS') &&
              !this.staticOpts.find((x) => x.name === 'MIS_CUPONES')
            ) {
              this.staticOpts.unshift(
                {
                  id: 11,
                  name: 'MIS_GIFTCARDS',
                  iconName: 'ticket-outline',
                  url: 'gift-cards',
                },
                {
                  id: 12,
                  name: 'MIS_CUPONES',
                  iconName: 'ticket-outline',
                  url: `gift-cards?coupon=${Types.COUPON}`,
                }
              );
            }
          }
        }

        if (
          this.paramSv.ableLogin === undefined ||
          this.paramSv.ableLogin?.canLogin
        ) {
          if (!this.staticOpts.find((x) => x.name === 'SALIR')) {
            this.staticOpts.push({
              name: 'SALIR',
              icon: '/assets/icons/salir.svg',
              url: 'logout',
            });
          }
        }
        const canLogin =
          this.paramSv.ableLogin === undefined ||
          this.paramSv.ableLogin?.canLogin;
        if (canLogin) {
          if (!this.staticOpts.find((x) => x.name === 'ORDENES_PENDIENTES')) {
            this.staticOpts.unshift({
              id: 2,
              name: 'ORDENES_PENDIENTES',
              iconName: 'reader-outline',
              url: 'pending-orders',
            });
          }

          if (!this.staticOpts.find((x) => x.name === 'FAVORITOS')) {
            this.staticOpts.unshift({
              id: 1,
              name: 'FAVORITOS',
              icon: '/assets/icons/heart-outline.svg',
              url: 'favorites',
            });
          }
        }
        if (!data) {
          if (canLogin) {
            this.staticOpts.unshift({
              name: 'INICIAR_SESION',
              icon: '/assets/icons/home-outline.svg',
              url: 'auth',
            });
          }
        } else {
          const indexLogin = this.staticOpts.findIndex(
            (item) => item.url === 'auth'
          );
          if (indexLogin !== -1) {
            this.staticOpts.splice(indexLogin, 1);
          }
        }
        if (this.roleId === 3 || this.roleId === 4) {
          this.staticOpts.unshift({
            name: 'ADMINISTRADOR',
            icon: '/assets/icons/administrador.svg',
            url: 'admin',
          });
        }
        await this.manageMenuItems();
      }, 100);
    } catch (error) {
      console.log('error addOptions :>> ', error);
    }
  }

  async manageMenuItems() {
    try {
      const views = await this.paramSv.getOnlyParam('menu-items');
      if (views) {
        const parsedViews: any[] = JSON.parse(views);
        this.staticOpts.forEach((item) => {
          const existView = parsedViews.find((x) => x.id === item.id);
          if (existView) {
            item.checked = existView.checked;
          }
        });
      } else {
        this.staticOpts.forEach((item) => (item.checked = false));
      }
      if (this.paramSv.modulesNames.length) {
        this.staticOpts.forEach((item) => {
          const module = this.paramSv.modulesNames.find(
            (module) => module.url === item.url
          );
          if (module) {
            item.name = module.newName;
          }
        });
      }
    } catch (error) {
      console.log('error manageMenuItems :>> ', error);
    }
  }

  comprobeAdminOption() {
    try {
      this.staticOpts = this.staticOpts.filter(
        (item) => item.url !== 'admin' && item.url !== 'operator'
      );
    } catch (error) {
      console.log('error comprobeAdminOption :>> ', error);
    }
  }

  setColors() {
    try {
      return new Promise((resolve) => {
        const matches = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches;
        const valueButtons = this.buttons?.value;
        if (valueButtons) {
          for (const key of Object.keys(valueButtons)) {
            const value = valueButtons[key];
            if (matches) {
              if (value?.darkBtnProperties) {
                for (const style of Object.keys(value?.darkBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.darkBtnProperties[style]
                  );
                }
              }
            } else {
              if (value?.lightBtnProperties) {
                for (const style of Object.keys(value?.lightBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.lightBtnProperties[style]
                  );
                }
              }
            }
          }
        }

        this.homeSections.forEach((section) => {
          const fixTitle: string = section.title
            .toLocaleLowerCase()
            .replace(/ /g, '_');
          const nameVar = `--${section.type}-${fixTitle}`;
          if (matches) {
            this.elementRef.nativeElement.style.setProperty(
              nameVar,
              section.darkBackgound
            );
          } else {
            if (section?.backgound) {
              this.elementRef.nativeElement.style.setProperty(
                nameVar,
                section.backgound
              );
            }
          }
        });

        this.colors.forEach((color) => {
          if (matches) {
            let paramDarkColor = color.darkColor;
            if (paramDarkColor.endsWith(';')) {
              paramDarkColor = paramDarkColor.slice(0, -1);
            }
            this.elementRef.nativeElement.style.setProperty(
              color.name,
              paramDarkColor
            );
          } else {
            let paramColor = color.color;
            if (paramColor.endsWith(';')) {
              paramColor = paramColor.slice(0, -1);
            }
            this.elementRef.nativeElement.style.setProperty(
              color.name,
              paramColor
            );
          }
        });

        this.elementRef.nativeElement.style.setProperty(
          '--ion-color-primary',
          'var(--primary--background)'
        );

        resolve(true);
      });
    } catch (error) {
      console.log('error setColors :>> ', error);
    }
  }

  trackByItems(index, item) {
    return item.id ? item.id : item.name;
  }
}
