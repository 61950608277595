import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcProductTax',
})
export class CalcProductTaxPipe implements PipeTransform {
  constructor() {}

  transform(value: string | number, tax: number = 0): number {
    const parsedValue = +value;
    const parsedValueWithTax = parsedValue + parsedValue * (tax / 100);

    const calculatedPrice = Math.trunc(parsedValueWithTax * 100) / 100;

    return calculatedPrice;
  }
}
