import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ParamServicesService } from './param-services.service';

enum CarruselOptions {
  ONE_TIME = 1,
  ALWAYS = 2,
}

interface EnableCarruselData {
  type: CarruselOptions.ALWAYS | CarruselOptions.ONE_TIME;
}
@Injectable({
  providedIn: 'root',
})
export class CarruselService {
  private _enableCarrousel: EnableCarruselData = {
    type: CarruselOptions.ONE_TIME,
  };

  constructor(
    private navCtrl: NavController,
    private paramSv: ParamServicesService
  ) {}

  async checkStatus() {
    let enableCarrousel = await this.paramSv.getOnlyParam('enableCarrusel');
    console.log('enableCarrousel :>> ', enableCarrousel);
    if (enableCarrousel) {
      this._enableCarrousel = JSON.parse(enableCarrousel as any);
      if (this._enableCarrousel.type === CarruselOptions.ALWAYS) {
        localStorage.removeItem('carrousel');
      }
    }

    const isNewUser = localStorage.getItem('carrousel');

    if (!isNewUser) {
      const redirectPath = `${window.location.pathname}${window.location.search}`;
      await this.navCtrl.navigateRoot('carrousel', {
        queryParams: {
          redirectPath: redirectPath.includes('/carrousel')
            ? 'home'
            : redirectPath,
        },
      });
    }
  }
}
