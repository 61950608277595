import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private loginSv: LoginService,
    private navCtrl: NavController
  ){}
  
  async canActivate(): Promise<any> {
    const user = await this.loginSv.getTokenUser();
    if(user.includes('undefined')){
      await this.navCtrl.navigateRoot('auth');
      return false;
    }else{
      return true;
    }
  }
}
